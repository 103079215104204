import React from "react";
import { HomeBackground } from "../components/Background";
import { Body } from "../components/Body";
import { Title } from "../components/Title";
import { Divider } from "../components/Divider";
import Text from "../components/Text";
import { data } from "../data/HomePageData";
import { Seo } from "../components/Seo";

const Index = () => (
  <>
    <Seo />
    <HomeBackground />
    <Body>
      <Title text="Home" className="text-red-hi" />
      <Divider />
      <div className="w-full my-3 px-3 md:px-6 max-w-3xl text-gray-common text-lg mb-5">
        <Text.Body>
          Hi, this is Yujin, a fan of LoveUnholyc.
          <br />
          <br />
          This walkthrough consists of:
        </Text.Body>
        <ul className="list-square list-inside">
          <Text.Body>
            <li>All routes including William's and Eater's</li>
            <li>All CGs</li>
            <li>All DLCs including 'We never forget you'</li>
          </Text.Body>
        </ul>
        <Text.Body>
          <br />
          Start by using the navigation bar on the left.
          <br />
          If there are any errors, typos or suggestions, please contact me via{" "}
          <a
            href="mailto:twinklepurple1117@gmail.com?subject=[LoveUnholyc Walkthrough]"
            className="underline"
          >
            email
          </a>
          .
          <br />
          <br />
          {"Have fun :)"}
          <br />
          <br />
          <br />
          <span className="text-xl">PrettyBusy officials:</span>
          <br />
        </Text.Body>
        <ul className="list-disc list-inside underline">
          <Text.Body>
            {data.map(({ en, link }) => (
              <li key={link} className="my-1">
                <a href={link}>{en}</a>
              </li>
            ))}
          </Text.Body>
        </ul>
      </div>
    </Body>
  </>
);

export default Index;
