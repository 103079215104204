export const data = [
  { en: "HomePage", ko: "홈페이지", link: "https://www.prettybusy.kr/" },
  { en: "Twitter", ko: "트위터", link: "https://twitter.com/Prettybusy_KR/" },
  { en: "Youtube", ko: "유튜브", link: "https://www.youtube.com/c/Prettybusy" },
  {
    en: "Instagram",
    ko: "인스타그램",
    link: "https://www.instagram.com/prettybusy_kr/",
  },
  {
    en: "Facebook",
    ko: "페이스북",
    link: "https://www.facebook.com/prettybusy.kr",
  },
];
